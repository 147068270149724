/* import __COLOCATED_TEMPLATE__ from './user-token-configuration-editor.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import type Token from 'embercom/models/custom-authentication/token';

interface Args {
  customAuthenticationToken: Token;
  form: any;
}

export default class UserTokenConfigurationEditor extends Component<Args> {
  get userTokenConfiguration() {
    return this.args.customAuthenticationToken.userTokenConfiguration;
  }

  get tokenKeyValidation() {
    return this.userTokenConfiguration.validations.attrs.tokenKey;
  }

  get ttlInSecondsValidation() {
    return this.userTokenConfiguration.validations.attrs.ttlInSeconds;
  }

  @action
  onTtlChange(ttlInSeconds: string) {
    this.userTokenConfiguration.ttlInSecondsValue = parseInt(ttlInSeconds, 10);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::CustomAuthentication::UserTokenConfigurationEditor': typeof UserTokenConfigurationEditor;
    'settings/custom-authentication/user-token-configuration-editor': typeof UserTokenConfigurationEditor;
  }
}
